/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "./assets/font-awesome/css/font-awesome.min.css";
@import "~leaflet/dist/leaflet.css";

@font-face{
    font-family: "Biennale Bold";
    src: url("./assets/fonts/Biennale_Bold.otf");
}

@font-face{
    font-family: "Biennale Medium";
    src: url("./assets/fonts/Biennale_Medium.otf");
}

@font-face{
    font-family: "Biennale Light";
    src: url("./assets/fonts/Biennale_Light.otf");
}

@font-face{
    font-family: "Biennale Regular";
    src: url("./assets/fonts/Biennale_Regular_It.otf");
}


.header{
    position: relative;
    overflow: hidden;
    height: 35%;
    padding-top: 2rem;
    // z-index: 1000;
}

.img-login{
    position: relative;
    // overflow: hidden;
    display: block;
    margin: auto;
    // height: 40%;
    z-index: 500;
    margin-top: 1rem;
}

.header::after{
    border-radius: 100%;
    position:absolute;
    background-color: #2b3643;
    right: -200px;
    left: -80px;
    top: -200px;
    content: '';
    bottom: 0px;
}

.text-head{
    position: relative !important;
    color: #f1f1f1;
    font-family: "Biennale Bold";
    z-index: 500;   
    margin-top: 1rem; 
}

.input{
    border-radius: 5px !important;
    border: 1px solid #2b3643;
    padding: 4vw 4vw 4vw 12vw;
    width: 100%;
    font-size: 4vw;
    font-family: "Biennale Light";    
}

.content-form{
    padding: 10vw 8vw 0vw 8vw !important;
    position: relative;
    margin-bottom: 16px;
}

.form-control i.icon{
    position: relative;
    // width: 25px !important;
    top: 10vw !important;
    font-size: 6vw;
    padding-left: 3vw;
    // right: 4vw;
}

.btn-continuar{
    padding: 15px 30px 15px 30px;
    width: 100%;
    border-radius: 30px;
    background-color: #2b3643;
    font-family: "Biennale Bold";
    color: #f1f1f1;
    margin-top: 6vw;
    font-size: 5vw;
}

p, small{
    font-family: "Biennale Light";
}

h3,h2{
    font-family: "Biennale Medium";
}

.recuperar{
    color: #2b3643;
    margin-top: 10vw;
    font-family: "Biennale Medium";
    font-size: 3.5vw;
}

i{
    color: #2b3643;
}

.calendario-modal{
    padding: 22vw 8vw 22vw 8vw !important;
}

.calendario-modal div.modal-wrapper{
    border-radius: 20px !important;
}

label{
    font-family: "Biennale Medium" !important;
}
